<template>
    <mouse-changer :type="mouseTypes.BlendMode">
        <div
            v-observer:callback="observerConfig"
            :id="id"
            class="text-wipe"
            :class="{ left: left }"
            v-if="header.title !== null"
        >
            <div class="content">
                <h2
                    class="header"
                    :class="{ none: noneLine }"
                    :style="`font-size: ${fontSize}px`"
                    v-html="header.title"
                ></h2>
                <!--      <h2 class="header colored">{{ header.teaser }}</h2>-->
            </div>
        </div>
    </mouse-changer>
</template>

<script>
import * as PIXI from "pixi.js";
import MouseChanger from "@/components/custom/MouseChanger";

export default {
    props: {
        left: {
            type: Boolean,
            required: false
        },
        noneLine: {
            type: Boolean,
            required: false
        },
        fontSize: {
            type: Number,
            required: false
        },
        id: {
            type: String,
            required: true
        },
        color: {
            type: Array,
            default: () => [0.039, 0.078, 0.11]
        },
        header: {
            type: Object,
            required: () => {}
        }
    },
    components: {
        MouseChanger
    },
    computed: {
        observerConfig() {
            return {
                callBack: this.render,
                animateOnObserve: true
            };
        }
    },
    methods: {
        render() {
            const app = new PIXI.Application({
                backgroundColor: 0x000000,
                backgroundAlpha: 0,
                resizeTo: window
            });
            document.getElementById(this.id).appendChild(app.view);
            app.view.height = 242;
            app.view.width = innerWidth;

            const perlinTexture = PIXI.Texture.from(
                require("../../assets/images/perlin.jpg")
            );

            // Build geometry.
            const geometry = new PIXI.Geometry()
                .addAttribute(
                    "aVertexPosition", // the attribute name
                    [
                        0,
                        0, // x, y
                        innerWidth,
                        0, // x, y
                        innerWidth,
                        242,
                        0,
                        242
                    ], // x, y
                    2
                ) // the size of the attribute
                .addAttribute(
                    "aUvs", // the attribute name
                    [
                        0.2,
                        0.2, // u, v
                        1,
                        0.5, // u, v
                        1,
                        1,
                        0,
                        1
                    ], // u, v
                    2
                ) // the size of the attribute
                .addIndex([0, 1, 2, 0, 2, 3]);

            const vertexSrc = `

    precision mediump float;

    attribute vec2 aVertexPosition;
    attribute vec2 aUvs;

    uniform mat3 translationMatrix;
    uniform mat3 projectionMatrix;

    varying vec2 vUvs;

    void main() {

        vUvs = aUvs;
        gl_Position = vec4((projectionMatrix * translationMatrix * vec3(aVertexPosition, 1.0)).xy, 0.0, 1.0);

    }`;

            const fragmentNoiseSrc = `
    precision mediump float;
    varying vec2 vUvs;
    uniform float zoom;
    uniform float mouse;
    uniform float limit;
    uniform vec3 color;
    uniform sampler2D noise;

    void main()
    {
        //Generate a simple grid.
        //Offset uv so that center is 0,0 and edges are -1,1
        vec2 uv = (vUvs-vec2(0.5))*2.0;
        vec2 gUv = floor(uv*zoom);
        vec4 color1 = vec4(vec3(color), 1.0);
        vec4 color2 = vec4(vec3(color), 1.0);
        vec4 outColor = mod(gUv.x + gUv.y, 2.) < 0.5 ? color1 : color2;

        float color = texture2D(noise, vUvs).r;
        color = step(limit, color);

        gl_FragColor = mix(vec4(0.,0.,0.,0.),outColor,color);

    }`;

            const noiseUniforms = {
                limit: 0,
                zoom: 5,
                noise: perlinTexture,
                color: this.color
            };
            const noiseShader = PIXI.Shader.from(
                vertexSrc,
                fragmentNoiseSrc,
                noiseUniforms
            );
            const noiseQuad = new PIXI.Mesh(geometry, noiseShader);
            app.stage.addChild(noiseQuad);

            let time = 12;

            app.ticker.add(() => {
                time += 1 / 60;
                // console.log(time)
                let endTime = Math.round((time + Number.EPSILON) * 100) / 100;

                if (endTime === 14) {
                    app.ticker.stop();
                    return;
                }
                noiseQuad.shader.uniforms.limit = Math.sin(time) * 0.35 + 0.5;
                noiseQuad.shader.uniforms.color = this.color;
                // waveQuad.shader.uniforms.time = time;

                // app.Renderer(waveQuad, waveTexture);
            });
        }
    }
};
</script>

<style lang="scss">
.text-wipe {
    position: relative;
    width: fit-content;
    margin: auto;
    &.left {
        margin: unset;
    }

    &.observed {
        .content {
            opacity: 1;
        }
    }

    .content {
        opacity: 0;
        transition: all 0.3s 0.1s ease-out;

        .header {
            font-size: 34px;
            font-style: normal;
            text-decoration: underline;
            text-decoration-color: #e5b676;
            text-decoration-thickness: 1px;
            text-underline-offset: 10px;
            color: white;
            text-align: left;
            font-weight: normal;
            will-change: transform;
            &.none {
                text-decoration: none;
                text-align: center !important;
            }

            &.colored,
            span {
                color: #e5b676;
            }

            // @media only screen and (max-width: 1440px) and (min-width: 1025px) {
            //     font-size: 55px !important;
            // }

            @media only screen and (max-width: 1024px) and (min-width: 415px) {
                font-size: 34px !important;
            }

            @media only screen and (max-width: 414px) {
                font-size: 21px !important;
                line-height: 29px;
                text-underline-offset: 3px;
            }
        }
    }
}

canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    pointer-events: none;
}
</style>
